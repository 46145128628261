<template>
	<div class="p-grid">
		<div class="p-col-12">
            <h3 style="color:#ffcc2a;">{{$t('Editar Reclamación')}} </h3>
		</div>
		<div class="p-col-12">
			<div class="card">
				<DataTable :value="claims" :scrollable="true" scrollHeight="400px" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                        :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
                    <template #header>
                        <div class="table-header">
                            <div>
                                <Dropdown v-model="opcion" :options="opciones" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')"></Dropdown>
                                <span class="p-input-icon-right">
                                    <i class="pi pi-search"/>
                                    <InputText v-model="buscar" :disabled="opcion==null"/>
                                </span>
                            </div>
                            <Button :disabled ="$store.state.loading" :label="$t('Buscar')" icon="pi pi-search" class="p-button-secondary" @click="Buscar"/>
                        </div>
                    </template>
					<Column field="id" :header="$t('ID')+' #'" headerStyle="width: 100px">
                        <template #body="slotProps">
                            {{slotProps.data.id}}                              
                        </template>
                    </Column>
                    <Column field="fecha" :header="$t('Fecha del incidente')" :sortable="true" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{convfech(slotProps.data.fecha)}}
                        </template>
                    </Column>
                    <Column field="nombre" :header="$t('Titular de la póliza')+' #'" :sortable="true" headerStyle="width: 200px">
                        <template #body="slotProps">
                            {{slotProps.data.nombre}}                              
                        </template>
                    </Column>
                    <Column field="poliz" :header="$t('Póliza')+' #'" :sortable="true" headerStyle="width: 200px">
                        <template #body="slotProps">
                            {{slotProps.data.poliz}}                        
                        </template>
                    </Column>
                    <Column field="codigo" :header="$t('Claim')+' #'" :sortable="true" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{slotProps.data.codigo}}                       
                        </template>
                    </Column>
                    <Column field="benef_nombre" :header="$t('Contraparte')" :sortable="true" headerStyle="width: 200px">
                        <template #body="slotProps">
                            {{slotProps.data.benef_nombre}}
                        </template>
                    </Column>
                    <Column :header="$t('Estatus')" field="estado_name" :sortable="true" headerStyle="width: 180px">
                        <template #body="slotProps">
							<strong :style="'color:'+slotProps.data.color" v-if="i18n.locale() == 'es'">
								{{slotProps.data.estado_nombre}}
							</strong>
							<strong :style="'color:'+slotProps.data.color" v-if="i18n.locale() == 'en'">
								{{slotProps.data.estado_name}}
							</strong>
                        </template>
                    </Column>   
                    <Column field="estimacion" :header="$t('Monto de Reserva')"  headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{moneda(slotProps.data.estimacion)}}                              
                        </template>
                    </Column>
                    <Column field="total" :header="$t('Pagar')" headerStyle="width: 180px">
                        <template #body="slotProps">
							{{moneda(slotProps.data.to_pay)}}
                        </template>
                    </Column>
                    <Column field="total" :header="$t('Pago')" headerStyle="width: 180px">
                        <template #body="slotProps">
							<div v-if="slotProps.data.benef_pagado">
								{{moneda(slotProps.data.benef_total)}}
							</div>
							<div v-else>
								{{moneda(0)}}
							</div>
                        </template>
                    </Column>
                    <Column :header="$t('Acciones')" headerStyle="width: 120px">
                        <template #body="slotProps">
                            <Button :disabled ="$store.state.loading" :label="$t('Editar')" icon="pi pi-pencil" class="p-button-info p-mb-2 p-mr-2"  @click="Editar(slotProps.data)" style="width: 100%;"/>
                        </template>
                    </Column>
                </DataTable>
			</div>
        </div>

        <Dialog v-model:visible="displayeditar" :style="{width: size}" :header="$t('Editar')" :modal="true" :maximizable="true">
            <br>
            <div class="p-field">
                <div class="p-grid">
                    <div class="p-field p-col-4">
                        <label>{{$t('Fecha del incidente')}}</label>
                        <InputMask v-model="claim.fechad" placeholder="DD/MM/YYYY" slotChar="DD/MM/YYYY" mask="99/99/9999"/>
                    </div>
                    <div class="p-col-8">
                        {{$t('Titular de la póliza')}}: <strong>{{claim.nombre}}</strong>			
                    </div>
                    <div class="p-col-4">
                        {{$t('Claim')+' #'}}: <strong>{{claim.codigo}}</strong>			
                    </div>
                    <div class="p-col-8">
                        {{$t('Póliza')+' #'}}: <strong>{{claim.poliz}}</strong>			
                    </div>
                    <div class="p-field p-col-4">
                        <label>{{$t('Estatus')}}</label>
                        <Dropdown v-model="estado" :options="estado1" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" v-if="opcion.code==1" style="width: 100%;"></Dropdown>
                        <Dropdown v-model="estado" :options="estado2" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" v-if="opcion.code==2" style="width: 100%;"></Dropdown>
                        <Dropdown v-model="estado" :options="estado3" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" v-if="opcion.code==3" style="width: 100%;"></Dropdown>
                        <Dropdown v-model="estado" :options="estado4" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" v-if="opcion.code==4" style="width: 100%;"></Dropdown>
                        <Dropdown v-model="estado" :options="estado5" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" v-if="opcion.code==5" style="width: 100%;"></Dropdown>
                        <Dropdown v-model="estado" :options="estado6" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" v-if="opcion.code==6" style="width: 100%;"></Dropdown>
                    </div>
                    <div class="p-col-8">
                        {{$t('Contraparte')}}: <strong>{{claim.benef_nombre}}</strong>			
                    </div>
                    <div class="p-col-3">
                        <label>{{$t('Monto de Reserva')}}</label>
                        <InputText v-model="claim.estimacion" type="number" style="width: 100%;"/>
                    </div>
                    <div class="p-col-3">
                        <label>{{$t('Total')}}</label>
                        <InputText v-model="claim.total" type="number" style="width: 100%;"/>
                    </div>
                    <div class="p-col-3">
                        <label>{{$t('Pagar')}}</label>
                        <InputText v-model="claim.to_pay" type="number" style="width: 100%;" disabled/>
                    </div>
                    <div class="p-col-3">
                        <label>{{$t('Pago')}}</label>
                        <InputText v-model="claim.benef_total" type="number" style="width: 100%;"/>
                    </div>
                    <div class="p-col-12">
                        <label>{{$t('Comentario')}}</label>
                        <InputText v-model="claim.observacion" style="width: 100%;"/>
                    </div>
                </div>
                <br>
            </div>
            <template #footer>
                <Button :disabled ="$store.state.loading" :label="$t('Guardar')" icon="pi pi-save" class="p-button-secondary" @click="Guardar" />
                <Button :disabled ="$store.state.loading" :label="$t('Cerrar')" icon="pi pi-times" class="p-button-danger" @click="displayeditar = false"/>
            </template>
        </Dialog>
    </div>
</template>

<script>
import API from "../service/API";
const Consulta = new API('Configuracion','EditClaim');
import { useI18nPlugin } from '@unify/vuex-i18n';
import url from "@/service/_URL";

export default {
	data() {
		return {
			selectedCustomers: null,
			filters: {},
			i18n: null,
            caso: null,
            claims: [],
            claim: null,
            opcion: null,
			opciones: [],

            poliza: [],
			url: null,
            buscar: null,
            polizas: [],
            displayview: false,
            police: {},
            duracion: [],
            estad: [],
            estados: [],
            cuotas: [],
            displayeditar: false,
            displaylic: false,
            displaycertif: false,
            displayreg: false,
            LicenciaDialog: false,
			categorias: [
                {nombre: 'A', code: 'A'},
                {nombre: 'B', code: 'B'},
                {nombre: 'C', code: 'C'},
                {nombre: 'D', code: 'D'},
                {nombre: 'E', code: 'E'},
			],
			licencia: {
                id: 0,
                poliza:0,
				code: null,
				nombre: null,
				categoria: null,
				emision: null,
				caducidad: null,
				pais: null,
				habitual: true,
				revocado: false,
				detalle: null,				
				adverso: null,			
				reverso: null,				
				verif: false				
			},
            paises: [],
			validez: [],
			pdf: null,
			img: null,
            size: '60vw',
            fecha: null,
            recargo: 0,
            estado: null,
            estado1: [],
            estado2: [],
            estado3: [],
            estado4: [],
            estado5: [],
            estado6: [],
        }
	},
	productService: null,
	eventService: null,
	created() {
		this.url = url;
		this.i18n = useI18nPlugin();
		this.$store.commit('Validar',this.$route);
		if (this.$store.state.mobile){this.size = '90vw';}
        this.Mostrar();
	},
	mounted() {
	},
	methods: {
        Mostrar() {
			Consulta.Ini().then(result => {
				this.insert = result.insert;
				this.edit = result.edit;
				this.del = result.delete;
				if (result.consult){
					this.opciones = result.consult.opciones;
                    this.estado1 = result.consult.estado1;
                    this.estado2 = result.consult.estado2;
                    this.estado3 = result.consult.estado3;
                    this.estado4 = result.consult.estado4;
                    this.estado5 = result.consult.estado5;
                    this.estado6 = result.consult.estado6;                    
				}
			}); 
		},
        Buscar(){
			this.poliza = [];
			Consulta.Procesar('Buscar',{
				id: this.buscar,
                opcion: this.opcion.code,
				}).then(response => {
                    if (response.result){
                        this.claims = response.result;
                    }
                });
        },
        Editar(data) {
            switch (this.opcion.code) {
                case 1:
                    this.estado = this.estado1.filter(val => val.code == data.estado)[0];
                    break;
                case 2:
                    this.estado = this.estado2.filter(val => val.code == data.estado)[0];
                    break;
                case 3:
                    this.estado = this.estado3.filter(val => val.code == data.estado)[0];
                    break;
                case 4:
                    this.estado = this.estado4.filter(val => val.code == data.estado)[0];
                    break;
                case 5:
                    this.estado = this.estado5.filter(val => val.code == data.estado)[0];
                    break;
                case 6:
                    this.estado = this.estado6.filter(val => val.code == data.estado)[0];
                    break;
            }
            this.claim = {...data};
            this.displayeditar = true;
        },
        Guardar() {
            this.$store.commit('Mobile');
            var fecha = Consulta.fdate(this.claim.fechad);
			Consulta.Procesar('Guardar',{
                claim: this.claim,
                opcion: this.opcion.code,
                fecha: fecha,
                estado: this.estado.code,
				}).then(response => {
                    //this.$store.state.error = response;
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: this.$t('Éxito'), 
							detail: this.$t('Información Procesada'), 
							life: 3000
						});
                        this.displayeditar = false;
						this.Buscar();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: this.$t('Advertencia'), 
							detail: this.$t('No fue procesada la solicitud'), 
							life: 3000
						});
                        this.displayeditar = false;
					}
                });           
        },
        convfech(fecha){
            return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
		},
        moneda(number){
            if(number== null){number=0;}
            return new Intl.NumberFormat("en", {style: "currency", currency: this.$store.state.currency}).format(parseFloat(number).toFixed(2));
		},
        fdate(fecha){
            const [dia, mes, ano] = fecha.split("/");
            let cambiada = `${ano}-${mes}-${dia}`;
            return cambiada;
        },
        toggle(event) {
			this.$refs.op.toggle(event);
		},

	},
	computed: {

    }

}
</script>
<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
