<template>
	<div class="p-grid">
		<div class="p-col-12">
            <h3 style="color:#ffcc2a;">{{$t('Insight QR')}} </h3>
		</div>
		<div class="p-col-12">
			<div class="card">
				<DataTable :value="poliza" :scrollable="true" scrollHeight="400px" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                        :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
                    <template #header>
                        <div class="table-header">
                            <Dropdown v-model="opcion" :options="opciones" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" @change="Buscar()"></Dropdown>
                            <span class="p-input-icon-right">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global']" :placeholder="$t('Buscar')+'...'" />
                            </span>
                        </div>
                    </template>
                    <Column :header="$t('Estatus')" field="estado_name" :sortable="true" headerStyle="width: 140px">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.estado==4">
                                <Avatar icon="bi bi-shield-exclamation" class="p-mr-2" size="large" style="background-color:yellow; color: #ffffff"/>
                                {{$t('Verificación  de Pago')}}
                            </div>
                            <div v-else-if="slotProps.data.estado==6">
                                <Avatar icon="bi bi-shield-slash" class="p-mr-2" size="large" style="background-color:red; color: #ffffff"/>
                                {{$t('Cancelada')}}
                            </div>
                            <div v-else-if="slotProps.data.estado==7">
                                <Avatar icon="bi bi-shield-slash" class="p-mr-2" size="large" style="background-color:red; color: #ffffff"/>
                                {{$t('Proceso Renovación')}}
                            </div>
                            <div v-else-if="slotProps.data.vend<0">
                                <Avatar icon="bi bi-shield-slash" class="p-mr-2" size="large" style="background-color:red; color: #ffffff"/>
                                {{$t('Vencida')}}
                            </div>
                            <div v-else-if="slotProps.data.pendiente>0">
                                <Avatar icon="bi bi-shield-exclamation" class="p-mr-2" size="large" style="background-color:yellow; color: #ffffff"/>
                                {{$t('Pago Pendiente')}}
                            </div>
                            <div v-else-if="slotProps.data.proceso>0">
                                <Avatar icon="bi bi-shield-slash" class="p-mr-2" size="large" style="background-color:red; color: #ffffff"/>
                                {{$t('Suspendida')}} <br> ({{$t('Pago Vencido')}})
                            </div>
                            <div v-else>
                                <Avatar icon="bi bi-shield-check" class="p-mr-2" size="large" style="background-color:green; color: #ffffff"/>
                                {{$t('Activa')}}
                            </div>
                        </template>
                    </Column>                       
                    <Column field="codigo" :header="$t('Póliza')+' #'" :sortable="true" headerStyle="width: 190px">
                        <template #body="slotProps">
                            {{slotProps.data.codigo}}<br>
                            <div v-if="i18n.locale()=='en'">{{slotProps.data.tipo_en}}</div>
                            <div v-if="i18n.locale()=='es'">{{slotProps.data.tipo_es}}</div>
                        </template>
                    </Column>
                    <Column field="nombre" :header="$t('Nombre Completo')+' #'" :sortable="true" headerStyle="width: 150px">
                        <template #body="slotProps">
                            {{slotProps.data.nombre}}  <br>
                            {{  $t('Cliente')+'# '+slotProps.data.cliente}}                            
                        </template>
                    </Column>
                    <Column :header="$t('Vehículo')" headerStyle="width: 150px">
                        <template #body="slotProps">
                            {{  $t('Placa')+': '+slotProps.data.placa}}  <br>
                            {{  $t('Licencia')+': '+slotProps.data.licencia}}  <br>
                            {{  $t('Marca')+': '+slotProps.data.marca}}  <br>
                            {{  $t('Modelo')+': '+slotProps.data.modelo}}  <br>
                            {{  $t('Año')+': '+slotProps.data.year}}   <br>
                            {{  $t('Chassis')+': '+slotProps.data.chasis}}   
                        </template>
                    </Column>
                    <Column field="activacion" :header="$t('Activación')" :sortable="true" headerStyle="width: 110px">
                        <template #body="slotProps">
                            {{slotProps.data.activacion}}
                        </template>
                    </Column>
                    <Column field="vence" :header="$t('Vencimiento')" :sortable="true" headerStyle="width: 110px">
                        <template #body="slotProps">
                            {{slotProps.data.vence}}
                        </template>
                    </Column>
                    <Column :header="$t('Estatus')" headerStyle="width: 130px">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.insight_id"> 
                                {{  $t('ID')+': '+slotProps.data.insight_id}} 
                            </div>
                        </template>
                    </Column>
                    <Column :header="$t('Acciones')" headerStyle="width: 200px">
                        <template #body="slotProps">
                            <Button icon="pi pi-eye" class="p-button-rounded p-button-primary p-mr-1" v-tooltip.bottom="$t('Estatus')" @click="Estatus(slotProps.data)" :disabled="!slotProps.data.insight_id || $store.state.loading "/>
                            <Button icon="pi pi-envelope" class="p-button-rounded p-button-secondary p-mr-1" v-tooltip.bottom="$t('Registrar')" @click="Registrar(slotProps.data)" :disabled="slotProps.data.insight_id || $store.state.loading" v-if="edit"/>
                            <Button icon="pi pi-save" class="p-button-rounded p-button-info p-mr-1" v-tooltip.bottom="$t('Actualizar')" @click="Actualizar(slotProps.data)" :disabled="!slotProps.data.insight_id || $store.state.loading"  v-if="insert"/>
                            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger p-mr-1" v-tooltip.bottom="$t('Borrar')" @click="Eliminar(slotProps.data)" :disabled="!slotProps.data.insight_id || $store.state.loading" v-if="del"/>
                        </template>
                    </Column>
                </DataTable>
			</div>
        </div>

       <Dialog v-model:visible="displayview" :style="{width: size}" :header="$t('Información Básica')" :modal="true" :maximizable="true">
            <br>
            <div class="confirmation-content">
                <div class="p-field">
                    <div class="p-grid">
                        <div class="p-col-12">
                            {{$t('Identifier')}}: <strong>{{info.identifier}}</strong>			
                        </div>
                        <div class="p-col-12">
                            {{$t('uri')}}: <strong>{{info.uri}}</strong>			
                        </div>
                        <div class="p-col-12">
                            {{$t('Generated Id')}}: <strong>{{info.generatedId}}</strong>			
                        </div>
                        <div class="p-col-12">
                            {{$t('Voided')}}: <strong>{{info.voided}}</strong>			
                        </div>
                        <div class="p-col-12">
                            {{$t('Created On')}}: <strong>{{info.createdOn}}</strong>			
                        </div>
                        <div class="p-col-12">
                            {{$t('Modified On')}}: <strong>{{info.modifiedOn}}</strong>			
                        </div>
                        <div class="p-col-12">
                            {{$t('Expiration Date')}}: <strong>{{info.expirationDate}}</strong>			
                        </div>
                        <div class="p-col-12">
                            {{$t('Pass Template Name')}}: <strong>{{info.passTemplateName}}</strong>			
                        </div>
                        <div class="p-col-12">
                            {{$t('link To Pass Page')}}: <strong>{{info.linkToPassPage}}</strong>			
                        </div>
                        <div class="p-col-12">
                            {{$t('QR Code Image')}}: <strong>{{info.qrCodeImage}}</strong>			
                        </div>
                        <div class="p-col-12">
                            {{$t('Barcode')}}: <strong>{{info.barcodeValue}}</strong>			
                        </div>
                        <div class="p-col-12">
                            {{$t('Valid till')}}: <strong>{{info.passFieldData.primaryFields_0_validtill}}</strong>			
                        </div>
                        <div class="p-col-12">
                            {{$t('Name')}}: <strong>{{info.passFieldData.secondaryFields_0_name}}</strong>			
                        </div>
                        <div class="p-col-12">
                            {{$t('Policy')}}: <strong>{{info.passFieldData.secondaryFields_1_policy}}</strong>			
                        </div>
                        <div class="p-col-12">
                            {{$t('Coverage')}}: <strong>{{info.passFieldData.backFields_0_Coverage}}</strong>			
                        </div>
                        <div class="p-col-12">
                            {{$t('Model')}}: <strong>{{info.passFieldData.backFields_1_Model}}</strong>			
                        </div>
                        <div class="p-col-12">
                            {{$t('Chasis')}}: <strong>{{info.passFieldData.backFields_2_Chasis}}</strong>			
                        </div>
                        <div class="p-col-12">
                            {{$t('Manufacturing year')}}: <strong>{{info.passFieldData.backFields_3_Manufacturingyear}}</strong>			
                        </div>
                        <div class="p-col-12">
                            {{$t('License')}}: <strong>{{info.passFieldData['5ead4c435494b8.75562471']}}</strong>			
                        </div>
                        <div class="p-col-12">
                            {{$t('Email')}}: <strong>{{info.passFieldData['5ead4c435496d4.08618691']}}</strong>			
                        </div>
                        <div class="p-col-12">
                            {{$t('Phone number')}}: <strong>{{info.passFieldData['5ead4c43549714.53258361']}}</strong>			
                        </div>
                        <div class="p-col-4">
                            <div class="p-grid">
                                <div class="p-col-12">
                                    <img :src="'https://app.insight-portal.com/en/passinstance/showpasslinkqrcode?passInstance%5B__identity%5D='+info.identifier" style="width: 200px; height: 200px;"/>		
                                </div>
                                <div class="p-col-12">
                                    <strong>Save your pass now!</strong> <i class="pi pi-print"></i>
                                </div>
                            </div>
                        </div>                       
                        <div class="p-col-4">
                            <div class="p-grid">
                                <div class="p-col-12">
                                    <img src="https://static.wixstatic.com/media/d8318a_2f2c75e5f2ff4d10b60b33d671a4a958~mv2.png/v1/fill/w_406,h_112,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/INSight%20logo%20HRes.png" style="width:100px;" alt="INSight%20logo%20HRes.png">
                                </div>
                                <div class="p-col-12">
                                    <img :src="'https://app.insight-portal.com/en/passinstance/showprintqrcode?passInstance%5B__identity%5D='+info.identifier" style="width: 150px; height: 150px;"/>		
                                </div>
                            </div>
                        </div> 
                        <div class="p-col-4">
                            <div class="p-grid">
                                <div class="p-col-12">
                                    <strong>Imported to Sabbe</strong>
                                </div>
                                <div class="p-col-12">
                                    <img :src="qr_insight" style="width: 150px; height: 150px;"/>
                                </div>
                            </div>
                        </div>                       
         

                    </div>
                </div>
                <br>
            </div>
        </Dialog>


    </div>
</template>

<script>
import API from "../service/API";
const Consulta = new API('Polizas','Insight');
import { useI18nPlugin } from '@unify/vuex-i18n';
import url from "@/service/_URL";
//import QRCode from 'qrcode';
//import QR from "qr-encode";

export default {
	components: {
    },
	data() {
		return {
            insert: false,
			edit: false,
			del: false,
			selectedCustomers: null,
			filters: {},
			i18n: null,
            caso: null,
            poliza: [],
			url: null,
			opcion: null,
			opciones: [{code: 0,   nombre: 'Todos', name: 'All'}],
            polizas: [],
            displayview: false,
            police: {},
            duracion: [],
            estad: [],
            estados: [],
			pdf: null,
            size: '60vw',
            fecha: null,
            recargo: 0,
            estatus: null,
            info: null,
            qr_insight: null

        }
	},
	productService: null,
	eventService: null,
	created() {
		this.url = url;
		this.i18n = useI18nPlugin();
		this.$store.commit('Validar',this.$route);
		if (this.$store.state.mobile){this.size = '90vw';}
        this.Mostrar();		
	},
	mounted() {
	},
	methods: {
        Mostrar(){
            Consulta.Ini().then(result => {
                this.insert = result.insert;
				this.edit = result.edit;
				this.del = result.delete;
                if (result.consult.estados){
                    result.consult.estados.forEach(result => {
                        this.opciones.push(result);
                    });
                }
                this.duracion = result.consult.duracion;
                this.mensaje = result.consult.mensaje;
            }); 
        },
        Buscar(){
			this.poliza = [];
			Consulta.Procesar('Buscar',{
				opcion: this.opcion.code,
				}).then(response => {
					//this.$store.state.error = response;	
                    if (response.result.poliza){
                        this.poliza = response.result.poliza;
                        this.poliza.vence = Consulta.fdate(this.poliza.vence);
                    }
                    this.$store.commit('Loading');
                });
        },
        Estatus(poliza){
            this.info = null;

            this.qr_insight = this.url.api+'qr_insight/'+poliza.insight_id+'.png';
			Consulta.Procesar('Ver',{
				id: poliza.insight_id,
				}).then(response => {
					if (response.result) {
                        this.info = response.result;
                        this.displayview = true;
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: this.$t('Advertencia'), 
							detail: this.$t('No fue procesada la solicitud'), 
							life: 3000
						});
					}
                });
        },
        Registrar(poliza){
			Consulta.Procesar('INS',{
				poliza: poliza.id,
				}).then(response => {
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: this.$t('Éxito'), 
							detail: this.$t('Información Procesada'), 
							life: 3000
						});
						this.Buscar();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: this.$t('Advertencia'), 
							detail: this.$t('No fue procesada la solicitud'), 
							life: 3000
						});
					}
                });
        },
        Actualizar(poliza){
			Consulta.Procesar('UPD',{
				poliza: poliza.id,
				}).then(response => {
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: this.$t('Éxito'), 
							detail: this.$t('Información Procesada'), 
							life: 3000
						});
						this.Buscar();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: this.$t('Advertencia'), 
							detail: this.$t('No fue procesada la solicitud'), 
							life: 3000
						});
					}
                });
        },
        Cancelar(poliza){
			Consulta.Procesar('CAN',{
				poliza: poliza.id,
				}).then(response => {
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: this.$t('Éxito'), 
							detail: this.$t('Información Procesada'), 
							life: 3000
						});
						this.Buscar();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: this.$t('Advertencia'), 
							detail: this.$t('No fue procesada la solicitud'), 
							life: 3000
						});
					}
                });
        },
        Eliminar(poliza){
			Consulta.Procesar('DEL',{
				poliza: poliza.id,
				}).then(response => {
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: this.$t('Éxito'), 
							detail: this.$t('Información Procesada'), 
							life: 3000
						});
						this.Buscar();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: this.$t('Advertencia'), 
							detail: this.$t('No fue procesada la solicitud'), 
							life: 3000
						});
					}
                });
        }




	},
	computed: {

    }

}
</script>
<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
