<template>
	<div class="p-grid">
		<div class="p-col-12">
            <h3 style="color:#ffcc2a;">{{$t('Consulta')}}: {{$t('Vehículo')}}</h3>
		</div>		
		<div class="p-col-12">
			<div class="card">
				<Toolbar class="p-col-12 p-md-12">
					<template v-slot:left>
						<div class="p-grid p-fluid">
							<div class="p-col-12 p-md-4" v-for="item of buscar" :key="item.key">
								<div class="p-inputgroup">
									<span class="p-inputgroup-addon p-inputgroup-addon-checkbox">
										<Checkbox v-model="item.estatus" :binary="true" @click="Limpiar(item.caso)"/>
									</span>
									<InputText v-model="item.text" :placeholder="item.name" :disabled="item.estatus==false"/>
								</div>
							</div>
							<div class="p-col-12 p-md-4">
								<strong>{{$t('Registros')}}: {{products.length}}</strong>
							</div>
						</div>
					</template>
					<template v-slot:right>

						<Button :disabled ="$store.state.loading" :label="$t('Buscar')" icon="pi pi-search" class="p-button-secondary p-mr-2 p-mb-2" @click="Buscar"/>						
					</template>
				</Toolbar>
	
				<DataTable :value="products" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                        :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">

					<Column :header="$t('Estatus')" field="estado_name" :sortable="true" headerStyle="width: 150px">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.estado==4">
                                <Avatar icon="bi bi-shield-exclamation" class="p-mr-2" size="large" style="background-color:yellow; color: #ffffff"/>
                                {{$t('Verificación  de Pago')}}
                            </div>
                            <div v-else-if="slotProps.data.estado==6">
                                <Avatar icon="bi bi-shield-slash" class="p-mr-2" size="large" style="background-color:red; color: #ffffff"/>
                                {{$t('Cancelada')}}
                            </div>
                            <div v-else-if="slotProps.data.estado==7">
                                <Avatar icon="bi bi-shield-slash" class="p-mr-2" size="large" style="background-color:red; color: #ffffff"/>
                                {{$t('Proceso Renovación')}}
                            </div>
                            <div v-else-if="slotProps.data.vend<0">
                                <Avatar icon="bi bi-shield-slash" class="p-mr-2" size="large" style="background-color:red; color: #ffffff"/>
                                {{$t('Vencida')}}
                            </div>
                            <div v-else>
                                <Avatar icon="bi bi-shield-check" class="p-mr-2" size="large" style="background-color:green; color: #ffffff"/>
                                {{$t('Activa')}}
                            </div>
                        </template>
                    </Column>                       



					<Column field="id" :header="$t('Póliza')+' #'" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.id}}                              
						</template>
					</Column>
					<Column field="usuario" :header="$t('Cliente')+' #'" :sortable="true">
                        <template #body="slotProps">
                            {{slotProps.data.usuario}}                              
                        </template>
                    </Column>
                    <Column field="nombre" :header="$t('Nombre Completo')+' #'" :sortable="true">
                        <template #body="slotProps">
                            {{slotProps.data.nombre}}                              
                        </template>
                    </Column>
                    <Column field="code" :header="$t('Número ID')+' #'" :sortable="true">
                        <template #body="slotProps">
                            {{slotProps.data.code}}                              
                        </template>
                    </Column>
                    <Column field="veh_chasis" :header="$t('Chassis')+' #'" :sortable="true">
                        <template #body="slotProps">
                            {{slotProps.data.veh_chasis}}                              
                        </template>
                    </Column>
                    <Column field="veh_placa" :header="$t('Placa')+' #'" :sortable="true">
                        <template #body="slotProps">
                            {{slotProps.data.veh_placa}}                              
                        </template>
                    </Column>
				</DataTable>
			</div>
		</div>
	</div>

</template>

<script>
	import API from "../service/API";
	const Consulta = new API('Consulta','Vehicle');

	export default {
		data() {
			return {
				nuevo: false,
				buscar: [
					{caso: 0, estatus: false, nombre: 'Número ID', name: 'ID Number', text: ''},
					{caso: 1, estatus: false, nombre: 'Chassis #', name: 'Chassis #', text: ''},
					{caso: 2, estatus: false, nombre: 'Placa #', name: 'Plate #', text: ''},
					{caso: 3, estatus: false, nombre: 'Póliza #', name: 'Police #', text: ''},
					{caso: 4, estatus: false, nombre: 'Cliente #', name: 'Customer #', text: ''},
					{caso: 5, estatus: false, nombre: 'Nombre de Cliente', name: 'Customer Name', text: ''},
				],
				opcion: true,
				insert: false,
				edit: false,
				del: false,
				products: [],
				origenes: [],
				productDialog: false,
				deleteProductDialog: false,
				deleteProductsDialog: false,
				product: {},
				selectedCustomers: null,
				filters: {},
				submitted: false,
				size: '60vw',
				roles: [] 
			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
		},
		mounted() {
			
		},
		methods: {
			Limpiar(id){
				if(!this.buscar[id].estatus){
					this.buscar[id].text = '';
				}
			},
			Buscar(){
				this.products = [];
				Consulta.Procesar('Buscar',{
					buscar: this.buscar,
					}).then(response => {
						if (response.result){
							this.products = response.result;
						}
					});
			},

		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
